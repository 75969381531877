<template>
  <div>
    <ModalHeader title="Thank you" />
    <div class="modal__content">
      <svg-icon class="confirm__modal" name="confirm_modal"></svg-icon>
      <h4>2FA successfully enabled</h4>
      <p class="subtitle confirm__modal-subtitle">
        You may now use the now Authenticator to log in
      </p>
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/elements/Modals/ModalHeader.vue';

export default {
  components: { ModalHeader },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.modal__content
  align-items: center
  padding: 4rem 6.4rem
  text-align: center

.modal__qr
  margin: 4rem 0

.modal__button
  margin: 4rem auto 0 auto

.modal__input
  text-align: start
  margin-top: 4rem

.modal__input-subtitle
  margin-top: 0.8rem

.confirm__modal
  max-width: 5.4rem
  max-height: 5.4rem
  margin-bottom: 4rem

.confirm__modal-subtitle
  margin-top: 0.8rem
</style>
