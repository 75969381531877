<template>
  <div>
    <ModalHeader title="Thank you" />
    <div class="modal__content">
      <svg-icon class="check-email__modal" name="check_email_modal"></svg-icon>
      <h4>Check your mail</h4>
      <p class="subtitle confirm__modal-subtitle">
        We've sent the status of your withdrawal request
      </p>
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/elements/Modals/ModalHeader.vue';

export default {
  components: { ModalHeader },
  data() {
    return {
      confirmationCode: 'LIVXI7OACQYBKR6P',
      emailCodeSent: 'Wor***@gmail.com',
    };
  },
  methods: {
    twoFactorSubmit() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'two-factor-success',
        // handler: { name: 'removeUserEuKey', payload: null },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.modal__qr
  margin: 4rem 0

.modal__button
  margin: 4rem auto 0 auto

.modal__input
  text-align: start
  margin-top: 4rem

.modal__input-subtitle
  margin-top: 0.8rem

.check-email__modal
  max-width: 5.4rem
  max-height: 5.4rem
  margin-bottom: 4rem

.confirm__modal-subtitle
  margin-top: 0.8rem
</style>
