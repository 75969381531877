<template>
  <div class="modal-security-verification">
    <ModalHeader title="Security verification" />
    <div class="modal__content">
      <div class="modal__text text">
        Enter the 6-digit code from Google Authenticator
      </div>
      <div class="modal__code-box">
        <VerificationCodeField
          :error="isVerificationCodeError"
          :error-txt="verificationCodeErrorText"
          @focus="resetServerError('otp')"
          @change="onChange"
          @complete="onComplete"
        />
      </div>
      <ButtonBase class="modal__button" @click="submit">Submit</ButtonBase>
    </div>
  </div>
</template>

<script>
import verifyCodeValidation from '@/mixins/validation/forms/verifyCode';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import VerificationCodeField from '@/elements/VerificationCodeField.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ModalHeader, VerificationCodeField },
  mixins: [verifyCodeValidation],
  data() {
    return {
      verificationCode: '',
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
  },
  methods: {
    onChange(value) {
      this.verificationCode = value;
    },
    onComplete(value) {
      this.verificationCode = value;
    },
    submit() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      let payload = {
        user_id: this.getModalProps.user_id,
        otp: this.verificationCode,
      };
      this.$store.dispatch('loginTwoFactor', payload);
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 5rem 7.4rem 4rem 7.4rem
  text-align: center
  @include ms
    height: calc(100vh - 62px)
    padding: 2.4rem 1.6rem

.modal__code-box
  margin-top: 3.4rem
  margin-bottom: 6.4rem
</style>
