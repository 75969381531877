<template>
  <button
    :class="cssClasses"
    class="btn"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <svg
      v-if="loading"
      class="loader"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="
        margin: auto;
        background: rgb(0, 0, 0);
        display: block;
        shape-rendering: auto;
      "
      width="20px"
      height="20px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#ffffff"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    gray: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // color: {
    //   type: String,
    //   required: true,
    // },
    // size: {
    //   type: String,
    //   required: true,
    // },
  },
  computed: {
    cssClasses() {
      return {
        'disabled-btn': this.disabled,
        gray: this.gray,
      };
    },
  },
};
</script>

<style lang="sass">
.btn
  display: flex
  position: relative
  align-items: center
  justify-content: center
  padding: 12px 32px
  background-color: $black
  font-weight: 500
  font-size: 12px
  line-height: 18px
  color: $white
  cursor: pointer
  transition: $transition-speed

.loader
  position: absolute
  left: 1rem

.disabled-btn
  background-color: $border-grey-color
  cursor: not-allowed
  color: $black

.gray
  background-color: $border-grey-color
  color: $black
</style>
