<template>
  <div>
    <ModalHeader title="Error" />
    <div class="modal__content">
      <svg-icon class="error__modal" name="error_modal"></svg-icon>
      <h4>An error occurred</h4>
      <p class="subtitle error__modal-subtitle">
        Sorry, but something went wrong
      </p>
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/elements/Modals/ModalHeader.vue';

export default {
  components: { ModalHeader },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.modal__input
  text-align: start
  margin-top: 4rem

.modal__input-subtitle
  margin-top: 0.8rem

.error__modal
  max-width: 5.4rem
  max-height: 5.4rem
  margin-bottom: 4rem

.error__modal-subtitle
  margin-top: 0.8rem
</style>
