<template>
  <div class="modal-forgot-password">
    <ModalHeader title="Forgot password" />
    <div class="modal__content">
      <div class="modal__text text">
        IF the email has not arrived during 30s you can still click the button
        below to resend it. We guarantee it will come this time!
      </div>
      <div class="modal__code-box">
        <VerificationCodeField
          :error="isVerificationCodeError"
          :error-txt="verificationCodeErrorText"
          @focus="resetServerError('code')"
          @change="onChange"
          @complete="onComplete"
        />
      </div>
      <button class="modal__caption" @click="openModal">
        Didn't receive the code?
      </button>
      <ButtonBase
        :loading="submitLoader"
        class="modal__button"
        @click="resetPasswordVerify"
      >
        Submit
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import VerificationCodeField from '@/elements/VerificationCodeField.vue';
import verifyCodeValidation from '@/mixins/validation/forms/verifyCode';

export default {
  components: { ModalHeader, VerificationCodeField },
  mixins: [verifyCodeValidation],
  data() {
    return {
      submitLoader: false,
      verificationCode: '',
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
  },
  methods: {
    onChange(value) {
      this.verificationCode = value;
    },
    onComplete(value) {
      this.verificationCode = value;
    },
    openModal() {
      this.resetServerError('code');
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'resend-verification-code',
        props: {
          email: this.getModalProps.email,
        },
      });
    },

    async resetPasswordVerify() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }

      this.submitLoader = true;
      await this.$store.dispatch('resetPasswordVerify', {
        email: this.getModalProps.email,
        code: this.verificationCode,
      });
      this.submitLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.modal-forgot-password .verification-code-box
  margin-bottom: 0

.modal__content
  align-items: center
  padding: 5rem 7.4rem 4rem 7.4rem
  text-align: center

.modal__code-box
  margin-top: 3.4rem

.modal__caption
  @include adaptive-font-size(14, 14, 12)
  @include adaptive-line-height(24, 24, 18)
  font-weight: 500
  color: $primary-color-blue
  margin: 2.4rem 0 6.4rem 0
  @include ms
    margin: 1rem 0 4rem 0

  &:hover
    text-decoration: underline
</style>
