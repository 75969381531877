<template>
  <div class="view profile-view">
    <!-- <HeaderMenu class="header-menu" /> -->
    <Sidebar />
    <Content />
  </div>
</template>

<script>
// import HeaderMenu from '@/components/Layout/HeaderMenu';
import Sidebar from '@/components/Layout/Sidebar';
import Content from '@/components/Layout/Content';

export default {
  components: {
    Sidebar,
    Content,
    // HeaderMenu,
  },
};
</script>

<style lang="sass" scoped>
.profile-view
  display: flex
  justify-content: start
  background-color: $background-color
  @include l
    flex-direction: column

// .header-menu
//   display: none
//   @include m
//     display: block

// .sidebar
//   @include m
//     display: none
</style>
