<template>
  <div>
    <ModalHeader title="Google Authenticator" />
    <div class="modal__content">
      <h4 class="modal__title">
        Are you sure you want to remove Google Authenticator?
      </h4>
      <p class="subtitle modal__subtitle">
        • Withdrawals, P2P selling, and payment services will be disabled for 24
        hours after you make this change to protect your account
      </p>
      <p class="subtitle modal__subtitle-second">
        • Two security verification methods are required for withdrawals and
        other actions. Using only one verification method will limit your
        withdrawals.
      </p>
      <div class="modal__input">
        <LabelField id="email-code" title="Email verification code" />
        <TextField
          id="email-code"
          v-model.trim="emailVerificationCode"
          name="email-code"
          tooltip-text=""
          type="text"
          :error="errorsHandler('email_verification_code') !== undefined"
          :error-txt="errorsHandler('email_verification_code')"
        />
        <p
          :class="{
            'modal__input-subtitle':
              errorsHandler('email_verification_code') === undefined,
            'modal__input-subtitle-error':
              errorsHandler('email_verification_code') !== undefined,
          }"
          class="subtitle"
        >
          Enter the 6 digit code sent to {{ emailCodeSent }}
        </p>
      </div>
      <div class="modal__input">
        <LabelField id="google-code" title="Authentication code" />
        <TextField
          id="google-code"
          v-model.trim="googleVerificationCode"
          name="google-code"
          tooltip-text=""
          type="text"
          :error="errorsHandler('otp') !== undefined"
          :error-txt="errorsHandler('otp')"
        />
        <p
          :class="{
            'modal__input-subtitle': errorsHandler('otp') === undefined,
            'modal__input-subtitle-error': errorsHandler('otp') !== undefined,
          }"
          class="subtitle"
        >
          Enter the 6-digit code from Google Authenticator
        </p>
      </div>
      <div class="modal__button-block">
        <ButtonBase gray class="modal__button" @click="close()"
          >Cancel</ButtonBase
        >
        <ButtonBase
          :loading="confirmLoader"
          class="modal__button"
          @click="twoFactorSubmit()"
          >Confirm</ButtonBase
        >
      </div>
    </div>
  </div>
</template>

<script>
import LabelField from '@/elements/LabelField';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ModalHeader, LabelField },
  data() {
    return {
      confirmLoader: false,
      emailVerificationCode: '',
      googleVerificationCode: '',
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser', 'getErrors']),
    emailCodeSent() {
      let indexOfAt = this.getCurrentUser.email.indexOf('@');
      let emailCodeSent =
        this.getCurrentUser.email.slice(0, 2) +
        '*'.repeat(indexOfAt - 2) +
        this.getCurrentUser.email.slice(indexOfAt);

      return emailCodeSent;
    },
  },
  methods: {
    close() {
      this.$store.commit('RESET_ERRORS');
      this.$store.commit('modal/SET_MODAL', { state: false });
      this.$store.commit('modal/CLEANE_MODAL_HISTORY_ROUTE');
    },
    errorsHandler(attr) {
      if (this.getErrors[attr]) {
        return this.getErrors[attr][0];
      }
    },
    async twoFactorSubmit() {
      let payload = {
        otp: this.googleVerificationCode,
        email_verification_code: this.emailVerificationCode,
      };
      this.confirmLoader = true;
      await this.$store.dispatch('disableTwoFactor', payload);
      await this.$store.dispatch('getProfile');
      this.confirmLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.modal__title
  margin-bottom: 4rem

.modal__subtitle
  margin-bottom: 2.4rem
  text-align: start

.modal__subtitle-second
  text-align: start

.modal__qr
  margin: 4rem 0

// .modal__button
//   margin: 4rem auto 0 auto

.modal__input
  text-align: start
  margin: 4rem 0 2rem 0
  max-width: 30rem
  width: 100%

.modal__input-subtitle
  margin-top: 0.8rem
  position: absolute

.modal__input-subtitle-error
  margin-top: 2.0rem
  position: absolute

.modal__button-block
  display: flex
  margin: 4rem auto 0 auto
  justify-content: center
  gap: 2.4rem
</style>
