<template>
  <header class="header">
    <div class="header__inner">
      <div class="header__logo">
        <MainLogo />
      </div>
    </div>
  </header>
</template>

<script>
import MainLogo from '@/components/Logo/MainLogo.vue';
export default {
  components: {
    MainLogo,
  },
};
</script>

<style lang="sass" scoped>
.header
  padding: 40px 40px 0 40px
  @include ms
    padding: 24px 0 0 0

.header__logo
  @include ms
    text-align: center
</style>
