<template>
  <div>
    <ModalHeader title="All referral income" />
    <div class="modal__content">
      <div class="select-month">
        <LabelField id="select-month" title="Select a month" />
        <SelectField
          :value="selectedMonth"
          required
          :options="monthOptions"
          @select-value="selectValue"
        />
      </div>
      <div class="referral-table">
        <div class="referral-table__header">
          <h5 class="subtitle">Name</h5>
          <h5 class="subtitle">Amount</h5>
        </div>
        <div class="referral-table__wrapper">
          <div
            v-for="(item, id) in referrals"
            :key="id"
            class="referral-table__item"
          >
            <h5>
              {{ item.full_name }}
            </h5>
            <h5 class="referral-table__amount">
              {{ item.amount }}
            </h5>
          </div>
        </div>
      </div>
      <ButtonBase class="modal__button" @click="okay()">Okay</ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import LabelField from '@/elements/LabelField';
import SelectField from '@/elements/SelectField';

export default {
  components: { ModalHeader, LabelField, SelectField },
  data() {
    return {
      selectedMonth: { title: 'All referral income', value: false },
      monthOptions: [{ title: 'All referral income', value: false }],
      referrals: {},
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
    ...mapGetters(['getErrors']),
  },
  async created() {
    const monthObject = await this.$store.dispatch('getReferralsMonth');
    for (const key in monthObject) {
      if (this.getModalProps === monthObject[key]) {
        this.selectedMonth = {
          title: monthObject[key],
          value: key,
        };
        // this.referrals = await this.$store.dispatch('getReferralsUsers', key);
      }
      this.monthOptions.push({
        title: monthObject[key],
        value: key,
      });
    }
    let payload = {
      month: this.selectedMonth.value,
    };
    this.referrals = await this.$store.dispatch('getReferralsUsers', payload);
  },
  methods: {
    async selectValue(item) {
      let payload = {
        month: item.value,
      };
      this.selectedMonth = item;
      this.referrals = await this.$store.dispatch('getReferralsUsers', payload);
    },
    okay() {
      this.$store.commit('modal/SET_MODAL', {
        state: false,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  width: 100%
  // height: 63rem

.modal__button
  margin: 2rem auto 0 auto

.select-month
  width: 100%

.referral-table__wrapper
  width: 100%
  height: 30rem
  overflow-y: scroll

.referral-table
  position: relative
  margin: 1.2rem 0
  width: 100%
  &::after
    width: 100%
    position: absolute
    bottom: 0
    left: 1.2rem
    height: 5%
    content: ""
    background: linear-gradient(to top, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 100%)
  &::before
    width: 100%
    position: absolute
    top: 4rem
    left: 1.2rem
    height: 2rem
    content: ""
    background: linear-gradient(to top, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 100%)

.referral-table__header
  display: grid
  grid-template-columns: 2fr 1fr
  height: 4rem
  padding: 1.2rem 2.4rem
  text-align: start
  margin-top: 0.6rem
  width: 100%
  // @include m
  //   display: none

.referral-table__item
  display: grid
  grid-template-columns: 2fr 1fr
  height: 4.8rem
  padding: 1.2rem 2.4rem
  text-align: start
  margin-top: 0.6rem
  width: 100%
  background-color: $background-table-color
  max-height: 30rem
  // @include m
  //   display: flex
  //   flex-direction: column
  //   height: auto
  //   padding: 2.4rem 2.4rem
  //   align-items: start
  //   gap: 1rem
  //   & :not(:first-child)
  //     font-size: 1.2rem

.referral-table__amount
  color: $primary-color-blue
</style>
