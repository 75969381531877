<template>
  <div class="modal-security-verification">
    <ModalHeader title="Didn't receive email code?" />
    <div class="modal__content">
      <div class="modal__text text">
        Code sent to your email. If you have not received the code after several
        attempts, please try:
      </div>
      <ul class="modal__list">
        <li class="modal__list-item text">
          1. Check if it is in your junk/spam mail.
        </li>
        <li class="modal__list-item text">
          2. Make sure your email address is name@mail.com
        </li>
        <li class="modal__list-item text">
          3. The message may be delayed for a few minutes. Try again after 10
          minutes.
        </li>
      </ul>
      <ButtonBase
        :loading="resendLoader"
        class="modal__button"
        @click="resendCodeHandler"
        >Resend code</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';

export default {
  components: { ModalHeader },
  data() {
    return {
      resendLoader: false,
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
  },
  methods: {
    async resendSignupCode() {
      this.resendLoader = true;
      await this.$store.dispatch('signupResendVerify', {
        email: this.$route.query.email,
      });
      this.resendLoader = false;
    },
    async resendResetPasswordCode() {
      this.resendLoader = true;
      await this.$store.dispatch('resetPassword', {
        email: this.getModalProps.email,
      });
      this.resendLoader = false;

      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'forgot-password',
        props: {
          email: this.getModalProps.email,
        },
      });
    },
    async resendCodeHandler() {
      if (this.$route.name === 'signup-verify') {
        await this.resendSignupCode();
      } else if (this.$route.name === 'auth') {
        await this.resendResetPasswordCode();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__list
  margin-top: 3rem
  @include ms
    margin-top: 2rem

.modal__button
  align-self: center
  margin-top: 4rem
</style>
