<template>
  <button
    :class="cssClasses"
    class="btn-withdrawal"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <h5>{{ text }}</h5>
    <div class="withdrawal-button-icon">
      <svg-icon v-show="isActive" name="withdrawal_button_icon"></svg-icon>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    // size: {
    //   type: String,
    //   required: true,
    // },
  },
  computed: {
    cssClasses() {
      return this.isActive ? 'btn-withdrawal-active' : '';
    },
  },
};
</script>

<style lang="sass">
.btn-withdrawal
  position: relative
  display: flex
  align-items: center
  justify-content: center
  padding: 12px 32px
  background-color: $background-color
  font-weight: 500
  font-size: 12px
  line-height: 18px
  color: $black
  cursor: pointer
  transition: $transition-speed

.btn-withdrawal-active
  background: linear-gradient(33.6deg, #2CA9CF -38.35%, #50D6FF 94.39%)
  color: $white

.withdrawal-button-icon
  position: absolute
  right: 0.5rem
  top: 0.5rem
  & svg
    max-height: 1.2rem
    max-width: 1.2rem
</style>
