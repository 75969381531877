<template>
  <div>
    <ModalHeader title="Confirm action" />
    <div class="modal__content">
      <h4 class="modal__title">Your income as a percentage</h4>
      <p class="subtitle modal__subtitle">
        The selected
        <span class="important">{{ getModalProps.percent.new }}%</span> of your
        income will be reinvested monthly on your balance.
      </p>
      <div class="modal__button-block">
        <ButtonBase gray class="modal__button" @click="cancelChangePercentage"
          >Cancel</ButtonBase
        >
        <ButtonBase
          :loading="confirmLoader"
          class="modal__button"
          @click="confirmChangePercentage"
          >Confirm</ButtonBase
        >
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ModalHeader },
  data() {
    return {
      confirmLoader: false,
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    ...mapGetters('modal', ['getModalProps']),
  },
  methods: {
    close() {
      this.$store.commit('modal/SET_MODAL', { state: false });
    },
    cancelChangePercentage() {
      this.$store.commit('UPDATE_USER', {
        ...this.getCurrentUser,
        compound_percentage: this.getModalProps.percent.old,
      });
      this.close();
    },
    async confirmChangePercentage() {
      this.confirmLoader = true;
      await this.$store.dispatch('changeCompoundPercent', {
        percent: this.getModalProps.percent.new,
      });
      this.confirmLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.modal__title
  margin-bottom: 1.2rem

.modal__subtitle
  .important
    color: $primary-color-blue

.modal__button-block
  display: flex
  margin: 4rem auto 0 auto
  justify-content: center
  gap: 2.4rem
</style>
