<template>
  <div>
    <ModalHeader title="Account frozen" />
    <div class="modal__content">
      <svg-icon class="crypto_logo" name="crypto_logo"></svg-icon>
      <p>
        The account is frozen 6 months from the moment of investment and the
        possibility to withdraw money will be available after 6 months.
      </p>
      <div v-if="getModalProps.freezeDataStart" class="frozen-dates">
        <h5>{{ getModalProps.freezeDataStart }}</h5>
        <svg-icon name="arrow_right_long"></svg-icon>
        <h5>{{ getModalProps.freezeDataEnd }}</h5>
      </div>
      <ButtonBase class="modal__button" @click="back()">Back</ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';

export default {
  components: { ModalHeader },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
  },
  methods: {
    back() {
      this.$store.commit('modal/SET_MODAL', {
        state: false,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.crypto_logo
  min-width: 5.4rem
  min-height: 5.4rem
  margin-bottom: 4rem

.frozen-dates
  background-color: $background-color
  display: flex
  width: 30rem
  padding: 1.3rem 0
  justify-content: center
  align-items: center
  gap: 1.8rem
  margin: 4rem auto
  & svg
    height: 1.2rem

.modal__button
  margin: 4rem auto 0 auto
</style>
