<template>
  <div class="view auth-view">
    <Header />
    <Content />
    <Footer v-if="isShowFooter" />

    <div class="figure figure--left">
      <img src="/images/left-figure.png" alt="" />
    </div>
    <div class="figure figure--right">
      <picture>
        <source
          srcset="/images/right-figure_mob.png"
          media="(max-width: 600px)"
        />
        <source srcset="/images/right-figure.png" />
        <img src="/images/right-figure.png" alt="" />
      </picture>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Layout/Header';
import Content from '@/components/Layout/Content';
import Footer from '@/components/Layout/Footer';

export default {
  components: {
    Header,
    Content,
    Footer,
  },
  computed: {
    isShowFooter() {
      return this.$route.name === 'auth' || this.$route.name === 'signup';
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-view
  flex-direction: column
  position: relative

  &:before,
  &:after
    position: absolute
    content: ''
    @include ms
      display: none

  &:before
    top: 0
    left: 20px
    width: 1px
    height: 500px
    background-image: url('/images/left-line.svg')

  &:after
    top: 20px
    left: 0
    width: 500px
    height: 1px
    background-image: url('/images/top-line.svg')

.figure
  position: absolute
  bottom: 0
  z-index: -1

.figure--left
  left: 0
  width: 298px
  height: 173px
  @include xxl
  @include height-l
    width: 196px
    height: 114px
  @include ms
    width: 120px
    height: 70px

.figure--right
  right: 0
  width: 299px
  height: 401px
  @include xxl
  @include height-l
    width: 197px
    height: 264px
  @include ms
    width: auto
    height: auto
</style>
