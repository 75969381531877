var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-box"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDollarSign),expression:"isDollarSign"}],staticClass:"dollar-sign"},[_vm._v("$")]),_c('input',{staticClass:"input",class:{
      error: _vm.error,
      disabled: _vm.isDisabled,
      password: _vm.isPassword,
      'dollar-sign__input': _vm.isDollarSign,
    },attrs:{"id":_vm.id,"type":_vm.fieldType,"placeholder":_vm.placeholder,"name":_vm.name,"min":_vm.min,"required":_vm.required,"autocomplete":"off","disabled":_vm.isDisabled},domProps:{"value":_vm.value},on:{"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"keypress":_vm.maskHandler,"input":_vm.onInput}}),(_vm.isPassword)?_c('button',{staticClass:"password-switcher",attrs:{"type":"button"},on:{"click":_vm.togglePassword}},[_c('svg-icon',{attrs:{"name":_vm.passwordSwitcherIcon}})],1):_vm._e(),(_vm.isPassword)?_c('div',{staticClass:"password-strength-meter"},_vm._l((9),function(item){return _c('div',{key:item,staticClass:"password-strength-meter__item",class:{
        alert: [1, 2, 3].includes(item) && _vm.value !== '',
        warning:
          [1, 2, 3, 4, 5, 6].includes(item) &&
          _vm.passwordStrength.minLength &&
          (_vm.passwordStrength.isHasUppercaseLetter ||
            _vm.passwordStrength.isHasNumber),
        success:
          _vm.passwordStrength.minLength &&
          _vm.passwordStrength.isHasUppercaseLetter &&
          _vm.passwordStrength.isHasNumber,
      }})}),0):_vm._e(),(_vm.error)?_c('InputError',{attrs:{"error-txt":_vm.errorTxt}}):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
      content: _vm.tooltipText,
      offset: 6,
    }),expression:"{\n      content: tooltipText,\n      offset: 6,\n    }",modifiers:{"right":true}}],staticClass:"input-box__info"},[_c('svg-icon',{staticClass:"input-box__hint",attrs:{"name":"input-info"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }