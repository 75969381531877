<template>
  <div>
    <ModalHeader title="Tether USD (USDT)" />
    <div class="modal__content">
      <svg-icon class="crypto_logo" name="crypto_logo"></svg-icon>
      <p class="subtitle">
        Minimum
        {{ getDashboardSettings.minimum_investment_amount_formatted }} USDT
      </p>

      <h2 class="invest-amount">{{ getModalProps.amount }}</h2>
      <img class="modal__qr" :src="getInvestmentInit.image" />
      <h4 class="erc-title">{{ getInvestmentInit.network }}</h4>
      <h5 class="confirmation-code">{{ getInvestmentInit.order_hash }}</h5>
      <p class="subtitle">
        If you are unable to scan the QR code, please enter the code manually
        into the app.
      </p>
      <ButtonBase :loading="doneLoader" class="modal__button" @click="done()"
        >Done</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';

export default {
  components: { ModalHeader },
  data() {
    return {
      doneLoader: false,
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
    ...mapGetters(['getInvestmentInit', 'getDashboardSettings']),
  },
  methods: {
    setWithdrawal(item) {
      this.buttonsArray.forEach((element) => {
        element.isActive = false;
      });
      item.isActive = !item.isActive;
    },
    async done() {
      this.doneLoader = true;
      await this.$store.dispatch(
        'completeInvestment',
        this.getInvestmentInit.order_hash
      );
      await this.$store.dispatch('getDashboard');
      this.doneLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.invest-amount
  margin: 2.4rem 0 0
  &::before
    content: '$'

.erc-title
  margin: 0 0 0.8rem

.confirmation-code
  margin-bottom: 0.8rem

.crypto_logo
  min-width: 5.4rem
  min-height: 5.4rem
  margin-bottom: 1rem

.modal__qr
  width: 25rem

.modal__button
  margin: 4rem auto 0 auto
</style>
