<template>
  <div class="verification-code-box">
    <div class="verification-code">
      <input
        v-for="(v, index) in values"
        :key="`${id}-${index}`"
        :ref="iRefs[index]"
        :type="type === 'number' ? 'tel' : type"
        :pattern="type === 'number' ? '[0-9]' : null"
        :autoFocus="autoFocus && index === autoFocusIndex"
        :data-id="index"
        :value="v"
        :disabled="disabled"
        :required="required"
        maxlength="1"
        class="verification-code__input"
        :class="{
          filled: !!v,
          error,
        }"
        @input="onValueChange"
        @focus="onFocus"
        @keydown="onKeyDown"
        @paste="onPaste"
      />
    </div>
    <InputError v-if="error" :error-txt="errorTxt" />
  </div>
</template>

<script>
import InputError from '@/elements/InputError.vue';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  right: 39,
};

export default {
  components: {
    InputError,
  },
  props: {
    type: {
      type: String,
      default: 'number',
    },
    fields: {
      type: Number,
      default: 6,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
    change: Function,
    complete: Function,
  },
  data() {
    const { fields, values } = this;
    let vals;
    let autoFocusIndex = 0;
    if (values && values.length) {
      vals = [];
      for (let i = 0; i < fields; i++) {
        vals.push(values[i] || '');
      }
      autoFocusIndex = values.length >= fields ? 0 : values.length;
    } else {
      vals = Array(fields).fill('');
    }
    this.iRefs = [];
    for (let i = 0; i < fields; i++) {
      this.iRefs.push(`input_${i}`);
    }
    this.id = +new Date();
    return { values: vals, autoFocusIndex };
  },
  mounted() {},
  methods: {
    onFocus(e) {
      e.target.select(e);
      this.$emit('focus');
    },
    onValueChange(e) {
      const index = parseInt(e.target.dataset.id);
      const { type, fields } = this;
      if (type === 'number') {
        e.target.value = e.target.value.replace(/[^\d]/gi, '');
      }
      // this.handleKeys[index] = false;
      if (
        e.target.value === '' ||
        (type === 'number' && !e.target.validity.valid)
      ) {
        return;
      }
      let next;
      const value = e.target.value;
      let { values } = this;
      values = Object.assign([], values);
      if (value.length > 1) {
        let nextIndex = value.length + index - 1;
        if (nextIndex >= fields) {
          nextIndex = fields - 1;
        }
        next = this.iRefs[nextIndex];
        const split = value.split('');
        split.forEach((item, i) => {
          const cursor = index + i;
          if (cursor < fields) {
            values[cursor] = item;
          }
        });
        this.values = values;
      } else {
        next = this.iRefs[index + 1];
        values[index] = value;
        this.values = values;
      }
      if (next) {
        const element = this.$refs[next][0];
        element.focus();
        element.select();
      }
      this.triggerChange(values);
    },
    onKeyDown(e) {
      const index = parseInt(e.target.dataset.id);
      const prevIndex = index - 1;
      const nextIndex = index + 1;
      const prev = this.iRefs[prevIndex];
      const next = this.iRefs[nextIndex];
      switch (e.keyCode) {
        case KEY_CODE.backspace: {
          e.preventDefault();
          const vals = [...this.values];
          if (this.values[index]) {
            vals[index] = '';
            this.values = vals;
            this.triggerChange(vals);
          } else if (prev) {
            vals[prevIndex] = '';
            this.$refs[prev][0].focus();
            this.values = vals;
            this.triggerChange(vals);
          }
          break;
        }
        case KEY_CODE.left:
          e.preventDefault();
          if (prev) {
            this.$refs[prev][0].focus();
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (next) {
            this.$refs[next][0].focus();
          }
          break;
        default:
          // this.handleKeys[index] = true;
          break;
      }
    },
    triggerChange(values = this.values) {
      const { fields } = this;
      const val = values.join('');
      this.$emit('change', val);
      if (val.length >= fields) {
        this.$emit('complete', val);
      }
    },
    onPaste(e) {
      e.preventDefault();
      e.stopPropagation();

      const copiedText = (e.originalEvent || e).clipboardData.getData(
        'text/plain'
      );

      const regexp = /^\d+$/;
      const isNumbers = regexp.test(copiedText);

      if (copiedText.length !== this.fields || !isNumbers) return;
      this.values = [...copiedText.split('')];

      const val = this.values.join('');
      this.$emit('change', val);
      this.$emit('complete', val);
    },
  },
};
</script>

<style lang="sass" scoped>
.verification-code-box
  display: inline-flex
  flex-direction: column
  position: relative
  margin-bottom: 24px
  @include ms
    margin-bottom: 10px

.verification-code
  display: flex
  align-items: center
  gap: 2.4rem
  @include ms
    gap: 1.2rem

.verification-code__input
  width: 4.4rem
  height: 4.4rem
  text-align: center
  font-weight: 500
  @include adaptive-font-size(14, 14, 12)
  @include adaptive-line-height(24, 24, 18)
  color: $black
  border: 1px solid $border-grey-color
  -moz-appearance: textfield
  @include ms
    width: 3rem
    height: 3rem

.verification-code__input.filled,
.verification-code__input:focus
  border: 1px solid $primary-color-blue

.verification-code__input.error
  border: 1px solid $negative-text

.verification-code-box .error-message
  position: relative
  bottom: auto
  justify-content: center
  margin-top: 12px
  @include ms
    margin-top: 8px
</style>
