<template>
  <div>
    <ModalHeader title="Tether USD (USDT)" />
    <div class="modal__content">
      <svg-icon class="crypto_logo" name="crypto_logo"></svg-icon>
      <p class="subtitle">
        Minimum
        {{ getDashboardSettings.minimum_investment_amount_formatted }} USDT
      </p>
      <div class="investment-amount__block">
        <LabelField id="investment-amount" title="Investment amount" />
        <TextField
          id="investment-amount"
          v-model.trim="investmentAmount"
          v-click-outside="dropError"
          is-dollar-sign
          tooltip-text="The investment amount must be at least $50000"
          min="0"
          name="investment-amount"
          value-mask="positive-number-integer"
          type="number"
          :error="errorsHandler('amount') !== undefined"
          :error-txt="errorsHandler('amount')"
          @input="onInput('amount')"
        />
      </div>
      <div class="investment-amount__block">
        <LabelField
          id="investment-address"
          title="ERC20 Sender wallet address"
        />
        <TextField
          id="investment-address"
          v-model.trim="investmentAddress"
          v-click-outside="dropError"
          name="investment-address"
          type="text"
          value-mask="latin-and-numbers"
          tooltip-text="Enter the address of the wallet from which you will invest"
          :error="errorsHandler('address') !== undefined"
          :error-txt="errorsHandler('address')"
          @input="onInput('address', investmentAddress)"
        />
      </div>
<!--      <h5 class="percentage-card__title">Rewards per month</h5>-->
<!--      <div class="percentage-card__block">-->
<!--        <div-->
<!--          v-for="(item, id) in getDashboardSettings.investment_percentage"-->
<!--          :key="id"-->
<!--          class="percentage-card__item"-->
<!--          :class="{-->
<!--            'percentage-card-active':-->
<!--              (+investmentAmount >= item.from &&-->
<!--                +investmentAmount < item.to &&-->
<!--                +investmentAmount != '0') ||-->
<!--              (+investmentAmount >= item.from && item.to == '0'),-->
<!--          }"-->
<!--        >-->
<!--          <svg-icon v-if="item.from == '0'" name="arrow_left_small"></svg-icon>-->
<!--          <svg-icon v-if="item.to == '0'" name="arrow_right_small"></svg-icon>-->
<!--          <h5 v-if="item.from != '0'">{{ item.from_formatted }}</h5>-->
<!--          <span v-if="item.from != '0' && item.to != '0'">-</span>-->
<!--          <h5 v-if="item.to != '0'">{{ item.to_formatted }}</h5>-->
<!--          <h5>&nbsp;- {{ item.percent }}%</h5>-->
<!--        </div>-->
<!--        &lt;!&ndash; <div-->
<!--          :class="{-->
<!--            'percentage-card-active':-->
<!--              +investmentAmount < 250000 && +investmentAmount > 0,-->
<!--          }"-->
<!--          class="percentage-card__top"-->
<!--        >-->
<!--          <svg-icon name="arrow_left_small"></svg-icon>-->
<!--          <h5>-->
<!--            {{ getDashboardSettings.investment_percentage['0'].to }}$ - -->
<!--            {{ getDashboardSettings.investment_percentage['0'].percent }}%-->
<!--          </h5>-->
<!--        </div>-->
<!--        <h5-->
<!--          :class="{-->
<!--            'percentage-card-active':-->
<!--              +investmentAmount >= 250000 && +investmentAmount <= 500000,-->
<!--          }"-->
<!--          class="percentage-card__center"-->
<!--        >-->
<!--          {{ getDashboardSettings.investment_percentage['1'].from }}$-{{-->
<!--            getDashboardSettings.investment_percentage['1'].to-->
<!--          }}$ - {{ getDashboardSettings.investment_percentage['1'].percent }}%-->
<!--        </h5>-->
<!--        <div-->
<!--          :class="{-->
<!--            'percentage-card-active': +investmentAmount > 500000,-->
<!--          }"-->
<!--          class="percentage-card__bottom"-->
<!--        >-->
<!--          <svg-icon name="arrow_right_small"></svg-icon>-->
<!--          <h5>-->
<!--            {{ getDashboardSettings.investment_percentage['2'].from }}$ - -->
<!--            {{ getDashboardSettings.investment_percentage['2'].percent }}%-->
<!--          </h5>-->
<!--        </div> &ndash;&gt;-->
<!--      </div>-->
      <ButtonBase
        :disabled="
          investmentAmount === '' ||
          investmentAmount < getDashboardSettings.minimum_investment_amount ||
          investmentAddress == ''
        "
        :loading="topUpLoader"
        class="modal__button"
        @click="topUp()"
        >Top up</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import LabelField from '@/elements/LabelField';

export default {
  components: { ModalHeader, LabelField },
  data() {
    return {
      topUpLoader: false,
      investmentAmount: '',
      investmentAddress: '',
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
    ...mapGetters(['getErrors', 'getDashboardSettings']),
  },
  methods: {
    dropError() {
      this.$store.commit('RESET_ERRORS');
    },
    errorsHandler(attr) {
      if (this.getErrors[attr]) {
        return this.getErrors[attr][0];
      }
    },
    async topUp() {
      this.topUpLoader = true;
      await this.$store.dispatch('initInvestment', {
        amount: this.investmentAmount,
        sender_address: this.investmentAddress,
      });
      this.topUpLoader = false;
    },
    onInput(field, value) {
      if (/[^a-zA-Z0-9]/.test(value)) {
        this.$store.commit('SET_ERRORS', {
          [field]: ['Field must contain only latin characters and numbers'],
        });
      } else {
        this.$store.commit('RESET_FIELD_ERROR', field);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.crypto_logo
  margin-bottom: 1rem
  min-width: 5.4rem
  min-height: 5.4rem

.investment-amount__block
  text-align: start
  max-width: 30rem
  margin: 2rem auto 1.2rem
  width: 100%

.percentage-card__block
  display: flex
  flex-direction: column
  justify-content: center
  gap: 0.4rem
  max-width: 30rem
  width: 100%
  margin: 2.4rem auto 0

.percentage-card__title
  margin-top: 4rem

.percentage-card__icon
  max-height: 2.4rem
  max-width: 2.4rem

.percentage-card__item
  display: flex
  padding: 1.3rem 1.8rem
  background-color: $background-color
  align-items: center
  & svg
    max-height: 1.2rem
    max-width: 0.8rem
    margin-right: 0.8rem

.percentage-card-active
  background: linear-gradient(33.6deg, #2CA9CF -38.35%, #50D6FF 94.39%)
  color: $white

.modal__button
  margin: 4rem auto 0 auto
</style>
