<template>
  <div :class="{ 'sidebar-open': getMenuIsOpen }" class="sidebar">
    <div :class="{ 'sidebar__top-open': getMenuIsOpen }" class="sidebar__top">
      <img class="logo__top" src="@/assets/images/logo_top.svg" />
      <div class="sidebar__menu-button" @click="toggleMenu()">
        <svg-icon class="menu__button" name="menu_button"></svg-icon>
      </div>
    </div>
    <div :class="{ 'sidebar__main-open': getMenuIsOpen }" class="sidebar__main">
      <img class="logo__image" src="@/assets/images/logo_image.svg" />
      <h5 v-if="getCurrentUser" class="sidebar__username">{{ fullName }}</h5>
      <p v-if="getCurrentUser" class="sidebar__email subtitle">
        {{ getCurrentUser.email }}
      </p>
      <div
        :class="{ 'sidebar__button-active': $route.name === 'dashboard' }"
        class="sidebar__button-dashboard"
        @click="goTo('/dashboard')"
      >
        <svg-icon class="dashboard__icon" name="dashboard"></svg-icon>
        <p>Dashboard</p>
      </div>
      <div
        :class="{ 'sidebar__button-active': $route.name === 'security' }"
        class="sidebar__button-security"
        @click="goTo('/security')"
      >
        <svg-icon class="security__icon" name="security"></svg-icon>
        <p>Security</p>
      </div>
      <div
        v-if="getDashboard.referral_system_enabled"
        :class="{ 'sidebar__button-active': $route.name === 'referral' }"
        class="sidebar__button-referral"
        @click="goTo('/referral')"
      >
        <svg-icon class="referral__icon" name="referral"></svg-icon>
        <p>Referral system</p>
      </div>
      <ButtonBase class="sidebar__button-logout" @click="logoutHandler">
        Log out
      </ButtonBase>
      <img class="logo__logout" src="@/assets/images/sidebar_logout.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getMenuIsOpen', 'getCurrentUser', 'getDashboard']),
    fullName() {
      return (
        this.getCurrentUser.first_name + ' ' + this.getCurrentUser.last_name
      );
    },
  },
  methods: {
    goTo(page) {
      if (this.$route.path !== page) {
        this.$router.push(page);
        this.$store.dispatch('setMenuState', false);
      }
    },
    toggleMenu() {
      this.$store.dispatch('setMenuState', !this.getMenuIsOpen);
    },
    logoutHandler() {
      this.$store.commit('LOGOUT_USER');
      this.$router.push({ name: 'auth' });
    },
  },
};
</script>

<style lang="sass" scoped>
.sidebar
  position: fixed
  height: 100vh
  background-color: $white
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1)
  @include l
    // position: relative
    height: 6.4rem
    position: relative

.sidebar-open
  height: 100%

.sidebar__top
  position: relative
  width: 24rem
  height: 12rem
  background-image: url('/images/modal_header.png')
  background-repeat: no-repeat
  background-size: 100% 100%
  @include l
    width: 100%
    height: 6.4rem

.sidebar__top-open
  height: 12rem
  background-color: $white

.sidebar__menu-button
  display: none
  @include l
    display: block
    position: absolute
    right: 4rem
    top: 1.6rem
    cursor: pointer

.sidebar__main
  z-index: 10
  display: flex
  align-items: center
  flex-direction: column
  height: calc(100vh - 16rem)
  @include l
    display: none

.sidebar__username
  margin-top: 5.6rem

.sidebar__main-open
  display: flex
  background-color: $white
  align-items: center
  flex-direction: column
  height: calc(100vh - 12rem)


.sidebar__email
  margin-bottom: 6.4rem

.sidebar__button-dashboard, .sidebar__button-security, .sidebar__button-referral
  display: flex
  align-items: center
  width: 24rem
  gap: 0.8rem
  height: 5.6rem
  width: 100%
  padding-left: 3rem
  cursor: pointer
  &:hover
    background-color: rgba(0, 0, 0, 0.03)
    box-shadow: inset 5px 0px 0px 0px #00C3FF

.sidebar__button-active
  background-color: rgba(0, 0, 0, 0.03)
  box-shadow: inset 5px 0px 0px 0px #00C3FF

.sidebar__button-logout
  z-index: 10
  margin: auto 0 0
  @include l
    margin: auto 0 4rem 0

.logo__top
  z-index: 10
  position: absolute
  left: 0
  right: 0
  top: 2.4rem
  margin: 0 auto
  @include l
    margin: auto
    top: 1.7rem
    left: 4rem
    margin: unset

.logo__image
  max-height: 8rem
  max-width: 8rem
  z-index: 10
  position: absolute
  left: 0
  right: 0
  top: 8rem
  margin: 0 auto

.logo__logout
  position: absolute
  left: 0
  right: 0
  bottom: 0
  margin-right: auto

.menu__button
  width: 100%
  height: 100%
  width: 3.2rem
  height: 3.2rem

.dashboard__icon
  max-width: 2.7rem
  max-height: 2.7rem

// .security__icon
//   max-width: 2.7rem
//   max-height: 2.7rem
//   & use
//     width: 100%
//     height: 100%
</style>
