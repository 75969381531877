<template>
  <a href="/" class="logo">
    <img src="@/assets/images/logo.svg" alt="Vyron" />
  </a>
</template>

<script>
export default {};
</script>

<style scoped></style>
