import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import modal from './modules/modal';

import {
  login,
  signup,
  loginTwoFactor,
  getDashboard,
  sendWithdrawalRequest,
  signupVerify,
  signupResendVerify,
  resetPassword,
  resetPasswordVerify,
  resetPasswordConfirm,
  changePassword,
  getTwoFactorSecret,
  sendEmailVerificationCode,
  enableTwoFactor,
  disableTwoFactor,
  getProfile,
  initInvestment,
  completeInvestment,
  changeCompoundPercent,
  getDashboardSettings,
  getReferralsMonth,
  getReferralsUsers,
} from '@/api/services';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { modal },

  state: {
    user: isLSHasItem('user') ? getFromLS('user') : null,
    token: isLSHasItem('token') ? getFromLS('token') : null,
    resetPasswordData: null,
    accountFrozenState: false,
    menuIsOpen: false,
    dashboard: [],
    dashboardSettings: {},
    errors: {},
    modalHistoryRouting: [],
    twoFactorSecret: {},
    profile: {},
    investmentInit: {},
    notification: null,
  },

  getters: {
    isAuthUser: (state) => !!state.token,
    getCurrentUser: (state) => state.user,
    getResetPasswordData: (state) => state.resetPasswordData,
    getAccountFrozenState: (state) => state.accountFrozenState,
    getMenuIsOpen: (state) => state.menuIsOpen,
    getDashboard: (state) => state.dashboard,
    getDashboardSettings: (state) => state.dashboardSettings,
    getProfile: (state) => state.profile,
    getErrors: (state) => state.errors,
    getTwoFactorSecret: (state) => state.twoFactorSecret,
    getInvestmentInit: (state) => state.investmentInit,
    getNotification: (state) => state.notification,
  },
  mutations: {
    SAVE_USER(state, payload) {
      const { token, user } = payload;
      state.user = user;
      state.token = token;
      setToLS('user', user);
      setToLS('token', token);
    },
    UPDATE_USER(state, user) {
      state.user = user;
    },
    LOGOUT_USER(state) {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
    SET_PASSWORD_DATA(state, payload) {
      state.resetPasswordData = payload;
    },
    RESET_PASSWORD_DATA(state) {
      state.resetPasswordData = null;
    },
    SET_ACCOUNT_FROZEN_STATE(state, payload) {
      state.accountFrozenState = payload;
    },
    SET_MENU_STATE(state, payload) {
      state.menuIsOpen = payload;
    },
    SET_DASHBOARD(state, payload) {
      state.dashboard = payload;
    },
    SET_DASHBOARD_SETTINGS(state, payload) {
      state.dashboardSettings = payload;
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    RESET_ERRORS(state) {
      state.errors = {};
    },
    RESET_FIELD_ERROR(state, fieldName) {
      state.errors[fieldName] = null;
    },
    SET_TWO_FACTORE_SECRET(state, payload) {
      state.twoFactorSecret = payload;
    },
    SET_INVESTMENT_INIT(state, payload) {
      state.investmentInit = payload;
    },
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
    },
    CLEAR_NOTIFICATION(state) {
      state.notification = null;
    },
  },
  actions: {
    async authorization({ commit, getters, mutations }, data) {
      let res;
      try {
        res = await login(data);
        const { token, user } = res.data.data;
        if (res.data.data.user.two_factor_enabled) {
          commit('modal/SET_MODAL', {
            state: true,
            name: 'security-verification',
            props: {
              user_id: res.data.data.user.id,
            },
          });
        } else {
          commit('SAVE_USER', {
            token,
            user,
          });

          router.push({ name: 'dashboard' });
        }
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async signup({ commit }, data) {
      let res;
      try {
        res = await signup(data);
        router.push({
          name: 'signup-verify',
          query: { email: data.email },
        });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async loginTwoFactor({ commit }, data) {
      let res;
      try {
        res = await loginTwoFactor(data);
        const { token, user } = res.data.data;
        commit('SAVE_USER', {
          token,
          user,
        });

        commit('modal/SET_MODAL', { state: false });
        router.push({ name: 'dashboard' });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async signupVerify({ commit }, data) {
      let res;
      try {
        res = await signupVerify(data);
        router.push({ name: 'signup-success' });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async signupResendVerify({ commit }, data) {
      let res;
      try {
        res = await signupResendVerify(data);
        commit('modal/SET_MODAL', { state: false });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async resetPassword({ commit }, data) {
      let res;
      try {
        res = await resetPassword(data);

        commit('modal/SET_MODAL', {
          state: true,
          name: 'forgot-password',
          props: {
            email: data.email,
          },
        });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async resetPasswordVerify({ commit }, data) {
      let res;
      try {
        res = await resetPasswordVerify(data);
        if (res.data.data.is_valid_code) {
          commit('SET_PASSWORD_DATA', {
            email: data.email,
            code: data.code,
          });
          commit('modal/SET_MODAL', { state: false });
          router.push({
            name: 'change-password',
          });
        }
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async resetPasswordConfirm({ commit }, data) {
      let res;
      try {
        res = await resetPasswordConfirm(data);
        commit('RESET_PASSWORD_DATA');
        router.push({
          name: 'auth',
        });
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async changePassword({ getters, commit, dispatch }, data) {
      let res;
      try {
        res = await changePassword(data);
        dispatch('setNotification', {
          type: 'success',
          title: 'New password',
          text: 'Password changed successfully',
        });
        return res.status === 200;
      } catch (error) {
        commit('SET_ERRORS', error.response.data.error);
      }
    },
    async sendWithdrawalRequest({ commit }, data) {
      let res;
      try {
        res = await sendWithdrawalRequest(data);
        commit('modal/SET_MODAL', {
          state: true,
          name: 'withdrawal-check-email',
        });
        commit('RESET_ERRORS');
      } catch (e) {
        console.log('>>> sendWithdrawalRequest error', e.response.data.error);
        commit('SET_ERRORS', e.response.data.error);
      }
    },
    async enableTwoFactor({ commit }, data) {
      let res;
      try {
        res = await enableTwoFactor(data);
        commit('modal/SET_MODAL', {
          state: true,
          name: 'two-factor-success',
        });
        commit('RESET_ERRORS');
      } catch (e) {
        console.log('>>> enableTwoFactor error', e.response.data.error);
        commit('SET_ERRORS', e.response.data.error);
      }
    },
    async disableTwoFactor({ commit }, data) {
      let res;
      try {
        res = await disableTwoFactor(data);
        commit('modal/SET_MODAL', {
          state: true,
          name: 'two-factor-disable-success',
        });
        commit('RESET_ERRORS');
      } catch (e) {
        console.log('>>> disableTwoFactor error', e.response.data.error);
        commit('SET_ERRORS', e.response.data.error);
      }
    },
    async changeCompoundPercent({ commit }, data) {
      try {
        const res = await changeCompoundPercent(data);
        if (res.status === 200) {
          commit('UPDATE_USER', res.data.data.user);
          setToLS('user', res.data.data.user);
          commit('modal/SET_MODAL', { state: false });
        }
      } catch (e) {
        console.log('>>> changeCompoundPercent error', e.response.data.error);
      }
    },
    async getDashboard({ commit }) {
      let res;
      try {
        res = await getDashboard();
        commit('SET_DASHBOARD', res.data.data);
      } catch (e) {
        console.log('>>> getDashboard error', e.response.data.status);
      }
    },
    async getReferralsMonth({ commit }) {
      let res;
      try {
        res = await getReferralsMonth();
        return res.data.data.months;
      } catch (e) {
        console.log('>>> getReferralsMonth error', e.response.data.status);
      }
    },
    async getReferralsUsers({ commit }, payload) {
      let res;
      try {
        res = await getReferralsUsers(payload);
        console.log(res.data.data);
        return res.data.data;
      } catch (e) {
        console.log('>>> getReferralsUsers error', e.response.data.status);
      }
    },
    async getDashboardSettings({ commit }) {
      let res;
      try {
        res = await getDashboardSettings();
        commit('SET_DASHBOARD_SETTINGS', res.data.data.settings);
      } catch (e) {
        console.log('>>> getDashboardSettings error', e.response.data.status);
      }
    },
    async getTwoFactorSecret({ commit }) {
      let res;
      try {
        res = await getTwoFactorSecret();
        commit('SET_TWO_FACTORE_SECRET', res.data.data);
      } catch (e) {
        console.log('>>> getTwoFactorSecret error', e.response.data.status);
      }
    },
    async getProfile({ commit }) {
      let res;
      try {
        res = await getProfile();
        commit('SET_PROFILE', res.data.data.user);
      } catch (e) {
        console.log('>>> getProfile error', e.response.data.status);
      }
    },
    async initInvestment({ commit }, data) {
      let res;
      try {
        res = await initInvestment(data);
        commit('modal/SET_MODAL', {
          state: true,
          name: 'investment-confirm',
          props: { ...data },
        });
        commit('SET_INVESTMENT_INIT', res.data.data);
        commit('RESET_ERRORS');
      } catch (e) {
        console.log('>>> initInvestment error', e.response.data.status);
        commit('SET_ERRORS', e.response.data.error);
      }
    },
    async completeInvestment({ commit }, data) {
      let res;
      try {
        res = await completeInvestment(data);
        commit('modal/SET_MODAL', {
          state: true,
          name: 'investment-success',
        });
      } catch (e) {
        console.log('>>> initInvestment error', e.response.data.status);
        commit('modal/SET_MODAL', {
          state: true,
          name: 'investment-error',
        });
      }
    },
    async sendEmailVerificationCode({ commit }) {
      let res;
      try {
        res = await sendEmailVerificationCode();
      } catch (e) {
        console.log(
          '>>> sendEmailVerificationCode error',
          e.response.data.status
        );
      }
    },
    setAccountFrozenState({ commit }, payload) {
      commit('SET_ACCOUNT_FROZEN_STATE', payload);
    },
    setMenuState({ commit }, payload) {
      commit('SET_MENU_STATE', payload);
    },
    setNotification({ commit, getters }, notification) {
      commit('SET_NOTIFICATION', notification);
      setTimeout(() => {
        if (getters.getNotification) {
          commit('CLEAR_NOTIFICATION');
        }
      }, 5000);
    },
  },
});
