<template>
  <div id="app" data-app>
    <Main />
    <Modal />
    <Notification />
  </div>
</template>

<script>
import Main from '@/components/Layout/Main';
import Modal from '@/elements/Modals/Modal.vue';
import Notification from '@/elements/Notification.vue';

export default {
  name: 'App',
  components: {
    Main,
    Modal,
    Notification,
  },
};
</script>
<style lang="sass"></style>
