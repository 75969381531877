<template>
  <div>
    <section
      v-click-outside="onClickOutside"
      :class="{
        disabled: isDisabled,
      }"
      class="dropdown-wrapper"
    >
      <div class="selected-item" @click="toggleList()">
        <input
          v-model="selectedValue[titleKey]"
          :class="{
            error: error,
          }"
          class="input"
          type="text"
          :disabled="!search"
          @input="onInput"
        />
        <div class="dropdown-arrow">
          <svg-icon
            :class="{ 'dropdown-arrow-rotate': isVisible }"
            name="dropdown_arrow"
            class="dropdown-arrow"
          />
        </div>
      </div>
      <InputError v-if="error" :error-txt="errorTxt" />
      <div v-show="isVisible" class="dropdown-popover">
        <div class="options">
          <ul>
            <li
              v-for="(item, idx) in options"
              :key="idx"
              @click="chooseItem(item)"
            >
              {{ item[titleKey] }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InputError from '@/elements/InputError.vue';

export default {
  components: {
    InputError,
  },
  model: {
    prop: 'selectedValue',
    event: 'select-value',
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {
        return {
          title: '',
        };
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: 'title',
    },
    errorTxt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedTitle: '',
      isVisible: false,
    };
  },
  computed: {
    selectedValue: {
      get() {
        console.log('get', this.value);
        return this.value;
      },
      set(value) {
        console.log('set', this.value);
        this.$emit('select-value', value);
      },
    },
  },
  methods: {
    onInput() {
      this.isVisible = true;
      this.$emit('input', this.value.title);
    },
    onClickOutside() {
      this.isVisible = false;
    },
    toggleList() {
      !this.isDisabled && (this.isVisible = !this.isVisible);
    },
    chooseItem(item) {
      this.selectedTitle = item[this.titleKey];
      this.isVisible = false;
      this.$emit('select-value', item);
    },
  },
};
</script>

<style lang="sass" scoped>
.input
  background: $white
  border: 1px solid $border-grey-color
  color: $black
  font-size: 12px
  line-height: 18px
  padding: 12px 18px
  font-weight: 400
  width: 100%
  height: 44px
  color: $text-primary-color
  opacity: 1

.disabled
  opacity: 0.8

.input.error
  border: 1px solid $negative-text

.selected-item
  position: relative

.dropdown-arrow
  position: absolute
  top: 0.3rem
  right: 1.2rem
  & svg
    max-width: 1.2rem

.dropdown-arrow-rotate
  transform: rotate(180deg)

.dropdown-wrapper
  position: relative
  .dropdown-popover
      z-index: 99
      position: absolute
      border: 1px solid $border-grey-color
      top: 4.4rem
      left: 0
      right: 0
      background-color: $white
      max-width: 100%
      input
        width: 90%
        height: 30px
        border: 1px solid $border-grey-color
        padding-left: 8px
      .options
        width: 100%
        ul
          list-style: none
          text-align: left
          max-height: 180px
          // overflow-y: scroll
          overflow-x: hidden
          font-size: 14px
          li
            display: flex
            width: 100%
            border-bottom: 1px solid lightgrey
            font-size: 12px
            line-height: 18px
            padding: 8px 18px
            cursor: pointer
            &:hover
              background-color: $border-grey-color
</style>
