<template>
  <v-dialog
    v-model="getModalState"
    persistent
    :max-width="`${modalWidth}px`"
    @keydown.esc="close"
    @click:outside="close"
  >
    <div class="modal modal__container">
      <div class="modal__container_content">
        <div class="modal__container_wrapper-cross" @click="close">
          <svg-icon name="cross" />
        </div>
        <component
          :is="modalName"
          @go-to-route="closeModalWithRouting"
        ></component>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ForgotPasswordModal from '@/elements/Modals/ForgotPasswordModal.vue';
import SecurityVerificationModal from '@/elements/Modals/SecurityVerificationModal.vue';
import ResendVerificationCodeModal from '@/elements/Modals/ResendVerificationCodeModal.vue';
import TwoFactorModal from '@/elements/Modals/TwoFactorModal.vue';
import ConnectAuthenticatorModal from '@/elements/Modals/ConnectAuthenticatorModal.vue';
import TwoFactorSuccessModal from '@/elements/Modals/TwoFactorSuccessModal.vue';
import TwoFactorDisableModal from '@/elements/Modals/TwoFactorDisableModal.vue';
import TwoFactorDisableSuccessModal from '@/elements/Modals/TwoFactorDisableSuccessModal.vue';
import AccountFrozenModal from '@/elements/Modals/AccountFrozenModal.vue';
import AccountFrozenAvailableModal from '@/elements/Modals/AccountFrozenAvailableModal.vue';
import WithdrawalAmountModal from '@/elements/Modals/WithdrawalAmountModal.vue';
import WithdrawalCheckEmailModal from '@/elements/Modals/WithdrawalCheckEmailModal.vue';
import InvestmentModal from '@/elements/Modals/InvestmentModal.vue';
import InvestmentConfirmModal from '@/elements/Modals/InvestmentConfirmModal.vue';
import InvestmentSuccessModal from '@/elements/Modals/InvestmentSuccessModal.vue';
import InvestmentErrorModal from '@/elements/Modals/InvestmentErrorModal.vue';
import ConfirmChangePercentageModal from '@/elements/Modals/ConfirmChangePercentageModal.vue';
import ReferralsAllModal from '@/elements/Modals/ReferralsAllModal.vue';

export default {
  components: {
    ForgotPasswordModal,
    SecurityVerificationModal,
    ResendVerificationCodeModal,
    TwoFactorModal,
    TwoFactorDisableModal,
    TwoFactorDisableSuccessModal,
    ConnectAuthenticatorModal,
    TwoFactorSuccessModal,
    AccountFrozenModal,
    AccountFrozenAvailableModal,
    WithdrawalAmountModal,
    WithdrawalCheckEmailModal,
    InvestmentModal,
    InvestmentConfirmModal,
    InvestmentSuccessModal,
    InvestmentErrorModal,
    ConfirmChangePercentageModal,
    ReferralsAllModal,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getCurrentUser']),
    ...mapGetters('modal', ['getModalState', 'getModalName', 'getModalProps']),
    modalName() {
      return this.getModalName ? `${this.getModalName}-modal` : '';
    },
    modalWidth() {
      switch (this.getModalName) {
        case 'two-factor':
        case 'two-factor-disable-success':
        case 'connect-authenticator':
        case 'two-factor-success':
        case 'two-factor-disable-success':
        case 'disable-two-factor':
        case 'withdrawal-check-email':
          return 428;
        case 'forgot-password':
        case 'security-verification':
        case 'resend-verification-code':
          return 800;
        case 'account-frozen':
        case 'account-frozen-available':
        case 'withdrawal-amount':
        case 'confirm-change-percentage':
          return 661;
        case 'two-factor-disable':
          return 548;
        case 'referrals-all':
          return 500;
        case 'investment':
        case 'investment-confirm':
        case 'investment-success':
        case 'investment-error':
          return 468;
        case 'confirm-delete':
          return 940;
        case 'reset-password':
          return 1000;
        case 'news-description':
          return 1040;
        default:
          return 940;
      }
    },
  },

  watch: {
    getModalState(value) {
      if (value) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    },
  },

  methods: {
    close() {
      if (this.getModalName === 'confirm-change-percentage') {
        this.$store.commit('UPDATE_USER', {
          ...this.getCurrentUser,
          compound_percentage: this.getModalProps.percent.old,
        });
      }
      this.$store.commit('modal/SET_MODAL', { state: false });
      this.$store.commit('RESET_ERRORS');
      this.$store.commit('modal/CLEANE_MODAL_HISTORY_ROUTE');
    },
    closeModalWithRouting(path) {
      this.close();
      this.$router.push(path);
      this.$store.commit('modal/CLEANE_MODAL_HISTORY_ROUTE');
    },
  },
};
</script>
<style lang="sass">
.modal__container_wrapper-cross
  position: absolute
  top: 4.8rem
  right: 4.8rem
  display: flex
  border-radius: 50%
  background: rgba(255, 255, 255, 0.25)
  cursor: pointer
  z-index: 2
  @include l
    top: 3.2rem
    right: 3.2rem

  @include ms
    top: 0.7rem
    right: 0.7rem
    border-radius: 0
    background: none

  & svg
    max-height: 4.8rem
    max-width: 4.8rem

.modal__container_content
  background-color: $white
  @include ms
    position: relative

.modal__content
  display: flex
  flex-direction: column
  align-items: center
  padding: 4rem 6.4rem
  overflow-y: auto
  @include ms
    min-height: calc(100vh - 62px)
    justify-content: center
    padding: 2.4rem 1.6rem !important
</style>
