<template>
  <div>
    <ModalHeader title="Withdrawal amount" />
    <div class="modal__content">
      <svg-icon class="crypto_logo" name="crypto_logo"></svg-icon>
      <p class="modal__subtitle">
        Available for withdrawal ${{
          getModalProps.availableAmountForWithdrawn
        }}
      </p>
      <div class="witdrawal-amount__block">
        <LabelField id="withdrawal-amount" title="Withdrawal amount" />
        <TextField
          id="withdrawal-amount"
          v-model.trim="withdrawalAmount"
          v-click-outside="dropError"
          is-dollar-sign
          name="withdrawal-amount"
          type="number"
          min="0"
          tooltip-text="Choose a percentage of the available amount or specify the withdrawal amount"
          :error="errorsHandler('amount') !== undefined"
          :error-txt="errorsHandler('amount')"
          @input="onInput('amount')"
        />
      </div>
      <h5 class="subtitle modal__percentage-subtitle">
        Possibility to fix the withdrawal amount % of the total amount
      </h5>
      <div class="withdrawal-buttons__block">
        <div
          v-for="item in buttonsArray"
          :key="item.text"
          class="withdrawal-buttons__block-button"
        >
          <ButtonWithdrawal
            :is-active="item.isActive"
            :text="item.text"
            @click="setWithdrawal(item)"
          />
        </div>
      </div>
      <div class="witdrawal-address__block">
        <LabelField id="withdrawal-address" title="Withdrawal address" />
        <TextField
          id="withdrawal-address"
          v-model.trim="withdrawalAddress"
          v-click-outside="dropError"
          name="address"
          type="text"
          tooltip-text="Enter the wallet address for withdrawal"
          :error="errorsHandler('address') !== undefined"
          :error-txt="errorsHandler('address')"
          @input="onInput('address', withdrawalAddress)"
        />
      </div>
      <div class="witdrawal-network__block">
        <LabelField id="withdrawal-network" title="Network" />
        <SelectField
          :value="selectedNetwork"
          :error="errorsHandler('network') !== undefined"
          :error-txt="errorsHandler('network')"
          :options="networkOptions"
          @select-value="selectValue"
        />
      </div>
      <ButtonBase
        :disabled="
          withdrawalAmount === '' ||
          withdrawalAmount == 0 ||
          withdrawalAmount > +getModalProps.availableAmountForWithdrawn
        "
        :loading="nextLoader"
        class="modal__button"
        @click="next()"
        >Next</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import LabelField from '@/elements/LabelField';
import SelectField from '@/elements/SelectField';
import ButtonWithdrawal from '@/elements/ButtonWithdrawal';

export default {
  components: { ModalHeader, LabelField, ButtonWithdrawal, SelectField },
  data() {
    return {
      nextLoader: false,
      selectedNetwork: { title: 'OMNI', value: 'OMNI' },
      withdrawalAmount: '',
      withdrawalAddress: '',
      networkOptions: [{ title: 'ERC20', value: 'ERC20' }],
      buttonsArray: [
        { text: '25%', isActive: false },
        { text: '50%', isActive: false },
        { text: '75%', isActive: false },
        { text: '100%', isActive: false },
      ],
    };
  },
  computed: {
    ...mapGetters('modal', ['getModalProps']),
    ...mapGetters(['getErrors']),
  },
  methods: {
    dropError() {
      this.$store.commit('RESET_ERRORS');
    },
    errorsHandler(attr) {
      if (this.getErrors[attr]) {
        return this.getErrors[attr][0];
      }
    },
    selectValue(item) {
      this.selectedNetwork = item;
    },
    setWithdrawal(item) {
      this.buttonsArray.forEach((element) => {
        element.isActive = false;
      });
      item.isActive = !item.isActive;
      this.withdrawalAmount = (
        (+this.getModalProps.availableAmountForWithdrawn *
          parseInt(item.text)) /
        100
      ).toString();
    },
    async next() {
      let payload = {
        amount: this.withdrawalAmount,
        address: this.withdrawalAddress,
        network: this.selectedNetwork.value.toLowerCase(),
      };
      this.nextLoader = true;
      await this.$store.dispatch('sendWithdrawalRequest', payload);
      this.nextLoader = false;
    },
    onInput(field, value) {
      if (/[^a-zA-Z0-9]/.test(value)) {
        this.$store.commit('SET_ERRORS', {
          [field]: ['Field must contain only latin characters and numbers'],
        });
      } else {
        this.$store.commit('RESET_FIELD_ERROR', field);
      }
      if (field === 'amount') {
        this.buttonsArray.forEach((element) => {
          element.isActive = false;
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.modal__subtitle
  margin-bottom: 2rem

.modal__percentage-subtitle
  margin-top: 2rem

.crypto_logo
  min-width: 5.4rem
  min-height: 5.4rem
  margin-bottom: 1rem

.witdrawal-amount__block, .witdrawal-address__block, .witdrawal-network__block
  text-align: start
  max-width: 30rem
  margin: 2rem auto
  width: 100%
  @include m
    max-width: none


.modal__button
  margin: 2rem auto 0 auto

.withdrawal-buttons__block
  display: flex
  gap: 2.4rem
  margin: 2.4rem auto 2rem
  justify-content: center
  @include m
    flex-wrap: wrap
    justify-content: space-between


.withdrawal-buttons__block-button
  @include m
    flex: 1 0 30%
    & button
      width: 100%
</style>
