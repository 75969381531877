]
<template>
  <div class="modal-header">
    <h4 class="modal-header__title">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Title',
    },
  },
};
</script>

<style lang="sass" scoped>

.modal-header
  position: relative
  height: 6.6rem
  width: 100%
  color: $white
  text-align: center
  line-height: 6.6rem
  background-image: url('/images/modal_header.png')
  background-repeat: no-repeat
  background-size: cover
  @include ms
    height: 6.2rem
    line-height: 6.2rem

.modal-header__title
  position: absolute
  top: 2rem
  width: 100%
  @include adaptive-font-size(24, 24, 18)
  @include adaptive-line-height(28, 28, 22)
</style>
