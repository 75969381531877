import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { checkUserToken } from '@/api/config';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      const isReferralCode = !!to.query.referral_code;

      if (isReferralCode) {
        store.commit('LOGOUT_USER');
        next({ name: 'signup', query: { ...to.query } });
      } else {
        next({ name: 'auth' });
      }
    },
  },
  {
    path: '/sign-in',
    name: 'auth',
    component: () => import('@/components/Auth/Auth.vue'),
    meta: {
      view: 'auth',
    },
  },
  {
    path: '/sign-up',
    component: () => import('@/components/SignUp/SignUp.vue'),
    meta: {
      view: 'auth',
    },
    children: [
      {
        path: '',
        name: 'signup',
        component: () => import('@/components/SignUp/SignUpForm.vue'),
        meta: {
          view: 'auth',
        },
      },
      {
        path: 'verify',
        name: 'signup-verify',
        component: () => import('@/components/SignUp/SignUpVerify.vue'),
        meta: {
          view: 'auth',
        },
      },
      {
        path: 'success',
        name: 'signup-success',
        component: () => import('@/components/SignUp/SignUpSuccess.vue'),
        meta: {
          view: 'auth',
        },
      },
    ],
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/components/Auth/ChangePassword.vue'),
    meta: {
      view: 'auth',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/components/Profile/Dashboard.vue'),
    meta: {
      view: 'profile',
    },
  },
  {
    path: '/security',
    name: 'security',
    component: () => import('@/components/Profile/Security.vue'),
    meta: {
      view: 'profile',
    },
  },
  {
    path: '/referral',
    name: 'referral',
    component: () => import('@/components/Profile/Referral.vue'),
    meta: {
      view: 'profile',
    },
  },
  {
    path: '/:notFound(.*)',
    name: 'not-found',
    component: () => import('@/components/NotFound/NotFound.vue'),
    meta: {
      view: 'auth',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const publicRoutes = [
    'home',
    'auth',
    'signup',
    'signup-verify',
    'signup-success',
    'change-password',
    'not-found',
  ];

  const isPublicRoute = publicRoutes.some((routeName) =>
    to.name.includes(routeName)
  );

  if (to.name === 'change-password' && !store.getters.getResetPasswordData) {
    next({ name: 'auth' });
  }

  if (!isPublicRoute && !checkUserToken()) {
    store.commit('LOGOUT_USER');
    next({ name: 'auth' });
  } else if (
    store.getters['modal/getModalState'] &&
    store.getters['modal/getModalHistoryRouting'].length > 1
  ) {
    store.commit(
      'modal/SET_MODAL',
      store.getters['modal/getModalHistoryRouting'][0]
    );
    store.commit('modal/POP_MODAL_HISTORY_ROUTE');
    store.commit('modal/POP_MODAL_HISTORY_ROUTE');
  } else if (store.getters['modal/getModalState']) {
    store.commit('modal/SET_MODAL', { state: false });
    store.commit('modal/CLEANE_MODAL_HISTORY_ROUTE');
    next();
  } else if (
    to.name === 'referral' &&
    !store.getters['getDashboard'].referral_system_enabled
  ) {
    next({ name: 'dashboard' });
  } else {
    next();
  }
});

export default router;
