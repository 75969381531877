<template>
  <div :class="{ hidden: getMenuIsOpen }" class="content">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getMenuIsOpen']),
  },
};
</script>

<style lang="sass" scoped>
.content
  flex-grow: 1

.hidden
  display: none

.auth-view .content
  display: flex
  align-items: center
  justify-content: center
  margin-top: -92px

  @media screen and (max-height: 900px)
    margin-top: 0
    padding-top: 40px
    padding-bottom: 80px

  @include ms
    padding-bottom: 40px

.profile-view .content
  margin-left: 24rem
  @include l
    margin-left: 0
</style>
