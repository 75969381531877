<template>
  <div>
    <ModalHeader title="Google Authenticator" />
    <div class="modal__content">
      <h4>Scan this QR code at the Authenticator</h4>
      <div v-html="getTwoFactorSecret.image"></div>
      <h4>{{ getTwoFactorSecret.secret }}</h4>
      <p class="subtitle">
        If you are unable to scan the QR code, please enter the code manually
        into the app.
      </p>
      <ButtonBase
        :loading="nextLoader"
        class="modal__button"
        @click="twoFactorNext()"
        >Next</ButtonBase
      >
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/elements/Modals/ModalHeader.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ModalHeader },
  data() {
    return {
      nextLoader: false,
    };
  },
  computed: {
    ...mapGetters(['getTwoFactorSecret']),
  },
  methods: {
    async twoFactorNext() {
      this.nextLoader = true;
      await this.$store.dispatch('sendEmailVerificationCode');
      this.nextLoader = false;
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'connect-authenticator',
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.modal__content
  padding: 4rem 6.4rem
  text-align: center

.modal__qr
  margin: 4rem 0

.modal__button
  margin: 4rem auto 0 auto
</style>
