import { Axios } from '@/api/config';

const api_version = '/api';

export const getDashboard = () => {
  const url = `${api_version}/dashboard`;
  return Axios.get(url);
};

export const getDashboardSettings = () => {
  const url = `${api_version}/dashboard/settings`;
  return Axios.get(url);
};

export const getProfile = () => {
  const url = `${api_version}/profile/me`;
  return Axios.get(url);
};

export const getReferralsMonth = () => {
  const url = `${api_version}/dashboard/referrals/months`;
  return Axios.get(url);
};

export const getReferralsUsers = (data) => {
  if (data.query && data.month) {
    const url = `${api_version}/dashboard/referrals/users?month=${data.month}&search_query=${data.query}`;
    return Axios.get(url, data);
  } else if (data.query) {
    const url = `${api_version}/dashboard/referrals/users?search_query=${data.query}`;
    return Axios.get(url, data);
  } else if (data.month) {
    const url = `${api_version}/dashboard/referrals/users?month=${data.month}`;
    return Axios.get(url, data);
  } else {
    const url = `${api_version}/dashboard/referrals/users`;
    return Axios.get(url);
  }
};

export const getTwoFactorSecret = () => {
  const url = `${api_version}/profile/security/two-factor/get-secret`;
  return Axios.get(url);
};

export const sendEmailVerificationCode = () => {
  const url = `${api_version}/profile/security/two-factor/send-email-verification-code`;
  return Axios.post(url);
};

export const initInvestment = (data) => {
  const url = `${api_version}/dashboard/invest/order/init`;
  return Axios.post(url, data);
};

export const completeInvestment = (data) => {
  const url = `${api_version}/dashboard/invest/order/${data}/complete`;
  return Axios.post(url, data);
};

export const sendWithdrawalRequest = (data) => {
  const url = `${api_version}/dashboard/withdrawn/withdrawal/create`;
  return Axios.post(url, data);
};

export const enableTwoFactor = (data) => {
  const url = `${api_version}/profile/security/two-factor/enable`;
  return Axios.post(url, data);
};

export const disableTwoFactor = (data) => {
  const url = `${api_version}/profile/security/two-factor/disable`;
  return Axios.post(url, data);
};

export const changeCompoundPercent = (data) => {
  const url = `${api_version}/profile/change-compound-percent`;
  return Axios.post(url, data);
};

export const login = (data) => {
  const url = `${api_version}/auth/sign-in`;
  return Axios.post(url, data);
};

export const loginTwoFactor = (data) => {
  const url = `${api_version}/auth/sign-in/two-factor/complete`;
  return Axios.post(url, data);
};

export const signup = (data) => {
  const url = `${api_version}/auth/sign-up`;
  return Axios.post(url, data);
};

export const signupVerify = (data) => {
  const url = `${api_version}/auth/sign-up/verify`;
  return Axios.post(url, data);
};

export const signupResendVerify = (data) => {
  const url = `${api_version}/auth/sign-up/verify/resend`;
  return Axios.post(url, data);
};

export const resetPassword = (data) => {
  const url = `${api_version}/auth/reset-password`;
  return Axios.post(url, data);
};

export const resetPasswordVerify = (data) => {
  const url = `${api_version}/auth/reset-password/verify`;
  return Axios.post(url, data);
};

export const resetPasswordConfirm = (data) => {
  const url = `${api_version}/auth/reset-password/confirm`;
  return Axios.post(url, data);
};

export const changePassword = (data) => {
  const url = `${api_version}/profile/security/change-password`;
  return Axios.post(url, data);
};
