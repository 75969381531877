var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalHeader',{attrs:{"title":"Google Authenticator"}}),_c('div',{staticClass:"modal__content"},[_c('h4',{staticClass:"modal__title"},[_vm._v(" Are you sure you want to remove Google Authenticator? ")]),_c('p',{staticClass:"subtitle modal__subtitle"},[_vm._v(" • Withdrawals, P2P selling, and payment services will be disabled for 24 hours after you make this change to protect your account ")]),_c('p',{staticClass:"subtitle modal__subtitle-second"},[_vm._v(" • Two security verification methods are required for withdrawals and other actions. Using only one verification method will limit your withdrawals. ")]),_c('div',{staticClass:"modal__input"},[_c('LabelField',{attrs:{"id":"email-code","title":"Email verification code"}}),_c('TextField',{attrs:{"id":"email-code","name":"email-code","tooltip-text":"","type":"text","error":_vm.errorsHandler('email_verification_code') !== undefined,"error-txt":_vm.errorsHandler('email_verification_code')},model:{value:(_vm.emailVerificationCode),callback:function ($$v) {_vm.emailVerificationCode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"emailVerificationCode"}}),_c('p',{staticClass:"subtitle",class:{
          'modal__input-subtitle':
            _vm.errorsHandler('email_verification_code') === undefined,
          'modal__input-subtitle-error':
            _vm.errorsHandler('email_verification_code') !== undefined,
        }},[_vm._v(" Enter the 6 digit code sent to "+_vm._s(_vm.emailCodeSent)+" ")])],1),_c('div',{staticClass:"modal__input"},[_c('LabelField',{attrs:{"id":"google-code","title":"Authentication code"}}),_c('TextField',{attrs:{"id":"google-code","name":"google-code","tooltip-text":"","type":"text","error":_vm.errorsHandler('otp') !== undefined,"error-txt":_vm.errorsHandler('otp')},model:{value:(_vm.googleVerificationCode),callback:function ($$v) {_vm.googleVerificationCode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"googleVerificationCode"}}),_c('p',{staticClass:"subtitle",class:{
          'modal__input-subtitle': _vm.errorsHandler('otp') === undefined,
          'modal__input-subtitle-error': _vm.errorsHandler('otp') !== undefined,
        }},[_vm._v(" Enter the 6-digit code from Google Authenticator ")])],1),_c('div',{staticClass:"modal__button-block"},[_c('ButtonBase',{staticClass:"modal__button",attrs:{"gray":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")]),_c('ButtonBase',{staticClass:"modal__button",attrs:{"loading":_vm.confirmLoader},on:{"click":function($event){return _vm.twoFactorSubmit()}}},[_vm._v("Confirm")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }